import * as React from "react"

import t from "@translations"
import Seo from "@components/seo"
import Layout from "@components/layout"
import { CommonPageWrap } from "@components/shared/styled"

type Props = {
    location: Location
}

const PrivacyPage = ({ location }: Props) => {
    return (
        <Layout location={location}>
            <Seo title={t.privacy} />
            <CommonPageWrap>
                <h1>{t.privacy}</h1>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed
                    rhoncus nulla eu sem molestie faucibus. Duis sit amet augue
                    sit amet arcu elementum imperdiet in sed leo. Sed blandit
                    pellentesque enim non consectetur. Phasellus ornare
                    malesuada enim quis ornare. Sed sem tellus, elementum ut
                    felis non, euismod dapibus quam. Nam vitae lorem vel augue
                    dignissim tristique. Nullam quis fringilla nunc, quis
                    eleifend mauris.
                </p>
                <p>
                    Quisque at dictum diam. Ut pulvinar, mauris ac dapibus
                    pretium, ipsum justo imperdiet mi, non fringilla est lectus
                    id est. Ut quis enim mi. Vivamus nec diam euismod, mollis
                    ipsum placerat, condimentum orci. Mauris purus enim,
                    scelerisque ut ligula et, varius tempor mi. Phasellus purus
                    dui, porta eu ipsum vitae, imperdiet tincidunt neque.
                    Praesent pretium nulla in mauris scelerisque, vel volutpat
                    felis suscipit. Proin condimentum arcu at massa egestas, sed
                    varius diam dapibus. Lorem ipsum dolor sit amet, consectetur
                    adipiscing elit. Vestibulum id cursus nunc. Etiam eros
                    libero, laoreet ac est in, fermentum laoreet diam. Etiam
                    luctus eu eros ut vestibulum. Aliquam nec turpis fermentum,
                    volutpat augue sit amet, suscipit risus. In sollicitudin
                    risus ligula, sed rutrum urna molestie ac. Vivamus pharetra
                    pharetra porta.
                </p>
                <p>
                    Integer sed sagittis elit. Nulla magna nunc, commodo at diam
                    nec, tristique varius enim. Nam tristique nisl mauris, et
                    vehicula ipsum convallis quis. Etiam tristique efficitur
                    ante, vitae efficitur tortor lobortis eu. Curabitur eget
                    tempus magna. In hac habitasse platea dictumst. Morbi leo
                    nisl, efficitur eget aliquet nec, commodo in lorem.
                </p>
                <p>
                    Phasellus accumsan ligula vitae nisl sollicitudin, et
                    interdum elit eleifend. Nullam pharetra scelerisque magna ut
                    finibus. Curabitur vitae tortor nec erat maximus tempus sed
                    quis turpis. Duis iaculis sed metus vitae porta. Nam
                    bibendum molestie justo, eu faucibus magna ultrices nec.
                    Pellentesque habitant morbi tristique senectus et netus et
                    malesuada fames ac turpis egestas. Nam condimentum venenatis
                    lectus, vitae viverra enim bibendum nec. Integer id eros et
                    enim semper commodo vel quis sem. Praesent malesuada eros
                    quis mi sagittis mollis. Etiam vitae cursus erat, a blandit
                    dui.
                </p>
            </CommonPageWrap>
        </Layout>
    )
}

export default PrivacyPage
